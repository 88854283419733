<template>
<div class="card-box-full">
  <div class="card-box-header">
    Fingerprint Finder
  </div>

    <div class="form-group row" style="margin-top: 15px; padding: 5px">
      <div class="col-md-12">
        <div class="input-group">

          <input class="form-control" v-model="searchMessage" autocomplete="on" placeholder="Enter a message" :disabled="isSearching" @keyup.enter="search()">
          <div class="input-group-append" color="primary">
            <CButton @click="search()" color="primary" :disabled="isSearching || searchMessage.length == 0" style="width: 80px">
              <div v-if="isSearching" class="spinner-border spinner-border-sm text-light" role="status" style="">
                <span class="sr-only"></span>
              </div>
              <div v-else>
                Find
              </div>
            </CButton>
          </div>
        </div>

        <!-- ----------------------------------------------------------------- -->


        <!-- ----------------------------------------------------------------- -->
        <div v-if="errorMessage">
          <div class="alert alert-danger" style="margin: 15px 0px 0px 0px" role="alert">{{ errorMessage }}</div>
        </div>

        <!-- ----------------------------------------------------------------- -->
        <div v-else-if="results.length > 0">

          <table class="table table-responsive-sm" style="margin-top: 10px; margin-bottom: -25px;">
            <thead>
              <tr style="font-size: 13px; border: 2px solid transparent">
                <th align="left">Similarity</th>
                <th align="left">Message</th>
                <th align="left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="result in results">
                <td width="100">
                  <CProgress style="width: 80px" class="mb-3" v-c-tooltip="{content: tooltipSimilarity(result), placement: 'top'}">
                    <CProgressBar v-if="result.similarity >= 0.7" color="success" :value="100 * result.similarity" />
                    <CProgressBar v-else-if="result.similarity >= 0.4" color="warning" :value="100 * result.similarity" />
                    <CProgressBar v-else color="danger" :value="100 * result.similarity" />
                  </CProgress>
                </td>
                <td>
                  <div class="alert alert-dark" style="padding: 5px; cursor: pointer" @click="clickedResult(result)">
                    {{ result.message }}
                  </div>
                </td>
                <td width="50" align="right">
                  <CButton @click="clickedSearchResult(result)" color="info" style="width: 40px; height: 30px; padding: 0px;">
                    <CIcon name="cil-search" />
                  </CButton>
                </td>
              </tr>
            </tbody>
          </table>

        </div>

      </div>
    </div>


</div>
</template>

<script>
export default {
  name: 'Search',
  data() {
    return {
      errorMessage: null,
      executionTime: "",
      results: [],
      isSearching: false,
      searchMessage: ""
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    clickedResult(result) {
      if (result && result.fingerprint) {
        this.$router.push({
          path: '/sms-profile/fingerprint/' + result.fingerprint
        });
      }
    },
    //--------------------------------------------------------------------------
    clickedSearchResult(result) {
      this.searchMessage = result.message;
      this.search();
    },
    //--------------------------------------------------------------------------
    parseMessage(body) {
      try {
        var result = JSON.parse(body);
        return result.message;
      } catch (err) {
        return "";
      }
    },
    //--------------------------------------------------------------------------
    processResponse(response) {

      if (response && response.execution_time) {
        this.executionTime = response.execution_time;
      }

      if (response && response.results) {
        this.results = response.results;
      }

      if (this.results.length == 0) {
        this.errorMessage = "No results were returned";
      }

    },
    //--------------------------------------------------------------------------
    search() {

      // Make sure we have a message
      if (!this.searchMessage) {
        return;
      }

      // Make sure we're not currently searching
      if (this.isSearching) {
        return;
      } else {
        this.isSearching = true;
        this.executionTime = "";
        this.results = [];
        this.errorMessage = null;
      }

      var that = this;
      var method = "POST";
      var body = JSON.stringify({
        message: this.searchMessage
      });
      var url = "https://sms-fingerprint.platform.robocalls.ai/search";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, body, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isSearching = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processResponse(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isSearching = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isSearching = false;
          that.errorMessage = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    tooltipSimilarity(result) {
      var value = 100 * result.similarity;
      return Math.floor(value) + "% match";
    }
  },
  mounted() {
    if (this.$route.params.message) {
      this.searchMessage = this.$route.params.message;
      this.search();
    }
  }
}
</script>
